<template>
  <section>
    <h3 class="head">
      {{ foodCategory.name }}
    </h3>

    <div
      v-if="foodCategory.description"
      class="description"
    >
      {{ foodCategory.description }}
    </div>

    <div class="dishes">
      <ol
        v-if="hasDishes"
        class="dish-list"
      >
        <li
          v-for="d in foodCategory.foodDishes"
          :key="d.id"
          class="dish"
        >
          <FoodDishComponent :food-dish="d" />
        </li>
      </ol>

      <div v-else>
        Momentan gibt es in dieser Kategorie keine Gerichte.
      </div>
    </div>
  </section>
</template>

<script>
import {FoodCategory} from "@/models/FoodCategory"
import FoodDishComponent from "@/components/FoodDish.vue"

export default {
  components: {
    FoodDishComponent
  },

  props: {
    foodCategory: {
      type: FoodCategory,
      required: true
    }
  },

  computed: {
    hasDishes() {
      return this.foodCategory.foodDishes &&
          this.foodCategory.foodDishes.length
    }
  }
}
</script>

<style lang="scss" scoped>
@use "vars";

.head {
  font-size: 1.5em;
  color: vars.$gold;
}

.description {
  margin-top: 0.5em;
}

.dishes {
  margin-top: 1em;
  padding: 2em 0;

  $b: 1px solid;
  border-top: $b;
  border-bottom: $b;
}

.dish-list {
  margin: 0;
  padding: 0;
}

.dish {
  margin: 1.5em 0 0 0;
  padding: 0;
  list-style-type: none;

  &:first-child {
    margin-top: 0;
  }
}
</style>
