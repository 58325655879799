import ImgData from "@/models/ImgData.js"
// import LinkDataExt from "@/models/LinkDataExt.js"
import {Content as SectionContent} from "@/components/SectionPostPic.vue"

export default class Schmecken {
  /**
   * @param {Object} p - params
   * @param {String} p.name
   * @param {String[]} [x.paragraphs=[]] - rendered with v-html
   * @param {ImgData[]} [x.imgArr=[]]
   * @param {SectionContent} [x.sectionPostGallery]
   * @param {String} p.foodCategoryLocation - load categories from this location
   * @param {Boolean} [x.includeTogoTerms=false]
   * @param {Boolean} [x.includeRsvBtn=false]
   * @param {Boolean} [x.isUnavailable=false]
   * @param {LinkDataExt|undefined} [x.linkPDFMenu] - adds BtnLinkExt
   * @param {SectionContent} [x.sectionBottom]
   */
  constructor(p) {
    const defaults = {
      paragraphs: [],
      imgArr: [],
      includeTogoTerms: false,
      includeRsvBtn: false,
      isUnavailable: false
    }
    Object.assign(this, defaults, p)
  }
}

// Maps /schmecken/:name to content
export const content = {
  "sushi-to-go": new Schmecken({
    name: "Sushi to go",
    paragraphs: [
      "SUSHI TRIFFT GOURMET",
      "Wir bringen Ihnen asiatische Genussmomente nach Hause oder Sie holen sich Ihr Sushi Menu oder Ihre Sushi Auswahl direkt bei uns in der Bolzstraße 8 ab."
    ],
    includeTogoTerms: true
  }),

  /*
  "gourmetshop-to-go": new Schmecken({
    name: "To Go - Gourmetshop",
    paragraphs: [
      "Unsere Auswahl wird in unserer Sterneküche frisch produziert.",
      "Selbstgemachtes aus Meisterhand."
    ],
    includeTogoTerms: true
  }),
  */

  "gourmet-restaurant": new Schmecken({
    name: "Gourmet Restaurant",
    paragraphs: [
      "In den unverwechselbaren Räumlichkeiten des ersten Stuttgarter Bahnhofs verzaubert Sie Sternekoch Alexander Dinter mit seiner Weltküche und dem Menu Passion."
    ],
    imgArr: [
      new ImgData("/img/2021_05_27/gourmet/gourmet.jpg", "Fisch"),
      new ImgData("/img/2021_05_27/gourmet/gourmet_food_2.jpg", "Speise auf braunem Teller"),
      new ImgData("/img/2021_05_27/gourmet/gourmet_food_3.jpg", "Fleisch auf weißem Teller"),
      new ImgData("/img/2021_05_27/gourmet/gourmet_food_1.jpg", "Dessert auf schwarzem Teller"),
      new ImgData("/img/gourmet1.jpg", "Speisen auf grüner Platte")
    ],
    sectionPostGallery: new SectionContent({
      header: "Passion von Alexander Dinter",
      headingLevel: "2",
      paragraphs: [
        "In allerbester Lage, im Herzen der Innenstadt, hat sich das 5 zum Ziel gesetzt, seine Gäste nach allen Regeln der Kunst zu verwöhnen.",
        "Auf zwei Etagen bietet das 5 eine einzigartige Mischung aus exzellenter Gourmetküche, stilechtem Restaurant, gemütlicher Lounge und schicker Bar.",
        "Sie lassen Ihre Seele baumeln, während wir uns um Ihr leibliches Wohl kümmern. Unsere Küche „Passion“ wird höchsten Ansprüchen gerecht und verzaubert seine Gäste mit Kochkünsten der Spitzenklasse."
      ]
    }),
    foodCategoryLocation: "schmecken/gourmet-restaurant",
    includeRsvBtn: true
  }),

  fruehstueck: new Schmecken({
    name: "Frühstück",
    paragraphs: [
      "Beginnen Sie den Tag mit einem Lächeln."
    ],
    imgArr: [
      new ImgData("/img/brunch7.jpg", "Frühstücks-Menu", {bgPos: "bottom"}),
      new ImgData("/img/brunch4.jpg", "Schinken und Käse"),
      new ImgData("/img/brunch2.jpg", "Croissant mit Marmelade"),
      new ImgData("/img/brunch3.jpg", "Müsli"),
      new ImgData("/img/brunch5.jpg", "Ei mit Kresse")
    ],
    sectionPostGallery: new SectionContent({
      header: "Frühstück aus Meisterhand",
      headingLevel: 2,
      paragraphs: [
        "Unser Küchenteam um unseren Sternkoch Alexander Dinter hat für Sie ein Gourmet Frühstück der Spitzenklasse konzipiert.",
        "Alle Produkte in Bio oder Demeter Qualität und aus regionaler nachhaltiger Produktion."
      ]
    }),
    foodCategoryLocation: "schmecken/fruehstueck",
    /*
    linkPDFMenu: new LinkDataExt({
      txt: "Alle Frühstücksangebote",
      href: "/pdf/fruehstueck_im_5.pdf",
      target: "_blank"
    }),
    */
    includeRsvBtn: true,
    sectionBottom: new SectionContent({
      header: "Ihr Frühstück in entspannter Atmosphäre",
      headingLevel: 3,
      paragraphs: [
        "Zeit für etwas Besonderes.",
        "5 Frühstück - Mehr als das tägliche Brot."
      ]
    })
  }),

  lunch: new Schmecken({
    name: "Lunch",
    paragraphs: [
      "In 5 Tagen um die Welt."
    ],
    imgArr: [
      new ImgData("/img/lunch1.jpg", "Salat"),
      new ImgData("/img/lunch4.jpg", "Risotto mit Pilzen"),
      new ImgData("/img/lunch3.jpg", "Steak mit Risotto"),
      new ImgData("/img/lunch2.jpg", "Burger mit Pommes"),
      new ImgData("/img/lunch5.jpg", "Gemüse mit Reis")
    ],
    sectionPostGallery: new SectionContent({
      header: "Eine kulinarische Genussreise",
      headingLevel: 2,
      paragraphs: [
        "Mit kreativen Gerichten als Mittagstisch schicken wir Sie auf eine Genussreise.",
        "Ein Lunchmenu rundet unser Angebot ab."
      ]
    }),
    foodCategoryLocation: "schmecken/lunch",
    includeRsvBtn: true,
    sectionBottom: new SectionContent({
      header: "Gutes Essen macht glücklich",
      headingLevel: 3,
      paragraphs: [
        "Volltanken statt Nachtanken. Gerade zur Mittagszeit benötigen wir essen das uns Energie spendet.  Gesunde Ernährung mit frischen hochwertigen Lebensmitteln ist die Basis unserer Mittagsküche.",
        "Starten Sie nach einem guten Mittagessen noch einmal richtig durch.",
        "Die private Atmosphäre unseres Gourmet Restaurants bietet den idealen Rahmen für Ihren Business Lunch."
      ]
    })
  }),

  "dinner-to-go": new Schmecken({
    name: "Abendkarte & Dinner Boxen to go",
    paragraphs: [
      "CHEF DE CUISINE - UNSER ABHOL- UND LIEFERSERVICE",
      "Wir bringen Ihr Leibgericht direkt zu Ihnen nach Hause!<br>In der aktuellen Situation wollen wir Sie mit verzehrfertigen Gerichten oder unseren Dinner Boxen verwöhnen."
    ],
    imgArr: [
      new ImgData("/img/dinner-to-go-5.jpg", "Suppe im goldenen Teller"),
      new ImgData("/img/dinner-to-go-1.jpg", "Vorspeise einer Dinner Box"),
      new ImgData("/img/dinner-to-go-2.jpg", "Hauptgericht einer Dinner Box"),
      new ImgData("/img/dinner-to-go-3.jpg", "Dessert einer Dinner Box"),
      new ImgData("/img/dinner-to-go-4.jpg", "Das befindet sich in einer Dinner Box")
    ],
    includeTogoTerms: true
  }),

  "bar-restaurant": new Schmecken({
    name: "Bar/Restaurant",
    paragraphs: [
      "Unser Feinschmecker Menu in 3-5 Gängen bringt Sie in unserem Bar Restaurant zum Schwärmen."
    ],
    imgArr: [
      new ImgData("/img/2021_05_27/bar_food_2.jpg", "Spaghetti"),
      new ImgData("/img/2021_05_27/bar_food_1.jpg", "Sushi"),
      new ImgData("/img/2021_05_27/bar_food_3.jpg", "Fleisch mit Beilage"),
      new ImgData("/img/wohnraum3.jpg", "5 Bar/Lounge"),
      new ImgData("/img/wohnraum5.jpg", "Tisch in der Bar/Lounge")
    ],
    foodCategoryLocation: "schmecken/bar-restaurant",
    includeRsvBtn: true,
    sectionBottom: new SectionContent({
      header: "Essen wie Gott in Stuttgart",
      headingLevel: 2,
      paragraphs: [
        "Genießen Sie die begleitenden Weine oder eine alkoholfreie Saftbegleitung."
      ]
    })
  })

  /*
  sternenzauber: new Schmecken({
    name: "Sternenzauber",
    paragraphs: [
      "Ihre # 4 Gang Gourmet Do It Yourself Dinner Box<br>Von Sternekoch Alexander Dinter",
      "CHEF DE CUISINE SIND SIE<br>-WERDEN SIE SELBST ZUM KOCH-",
      "Zaubern Sie unter Anleitung von Sternekoch Alexander Dinter<br>mit wenigen Handgriffen Ihr Gourmet Menu in den eigenen 4 Wänden.",
      "Für Lieferungen bis 25 km von Mittwoch bis Sonntag von 17.00 Uhr bis 20:00 Uhr.<br>Bestellung einen Tag im Voraus.",
      "Anlieferung deutschlandweit samstags bis 12:00 Uhr (Lieferkosten 30 € pro Box).<br>Telefonische Bestellung bis Donnerstag 16:00 Uhr."
    ],
    imgArr: [
      new ImgData("/img/sternenzauber/4.jpg", "Brot mit Butter im Glas"),
      new ImgData("/img/sternenzauber_2/kingfish.jpg", "King Fish"),
      new ImgData("/img/sternenzauber_2/soup.jpg", "Parmesan Suppe"),
      new ImgData("/img/sternenzauber_2/meat.jpg", "Weidekalb"),
      new ImgData("/img/sternenzauber_2/cake.jpg", "Schokoladen-Bananen-Traum")
    ],
    linkPDFMenu: new LinkDataExt({
      txt: "Zur Anleitung",
      href: "/pdf/diy_anleitung_2021_04_17.pdf",
      target: "_blank"
    })
  })
  */

  /*
  ostern: new Schmecken({
    name: "Ostern im 5",
    paragraphs: [
      "5 Lieferservice<br>Zeit zum Genießen",
      "Zaubern Sie unter Anleitung von Sternekoch Alexander Dinter<br>mit wenigen Handgriffen Ihr Gourmet Oster Menu in den eigenen 4 Wänden.",
      "Mit unseren Ostern Dinner Boxen verwöhnen wir Sie kulinarisch von Herzen.",
      "Wir freuen uns auf Ihre Bestellungen.<br>Ihr Team 5"
    ],
    imgArr: [
      new ImgData("/img/sternenzauber/4.jpg", "Brot mit Butter im Glas"),
      new ImgData("/img/sternenzauber_2/kingfish.jpg", "King Fish"),
      new ImgData("/img/sternenzauber_2/soup.jpg", "Parmesan Suppe"),
      new ImgData("/img/sternenzauber_2/meat.jpg", "Weidekalb"),
      new ImgData("/img/sternenzauber_2/cake.jpg", "Schokoladen-Bananen-Traum")
    ],
  })
  */

  /*
  "weihnachten-silvester-im-5": new Schmecken({
    name: "Weihnachten & Silvester im 5",
    paragraphs: [
      "Liebe Gäste,<br>wir möchten Ihnen die Weihnachtsfeiertage und den Jahreswechsel kulinarisch verschönern.",
      "Unser Küchenteam um Sternekoch Alexander Dinter hat für sie eine wunderbare Auswahl für die Weihnachtsfeiertage und Silvester zusammengestellt.",
      "Stellen Sie für die Weihnachtstage Ihr traumhaftes 3 Gang Menu zusammen oder wählen Sie an Silvester zwischen einem 3 Gang Menu oder einer Fondue & Raclette Box."
    ],
    imgArr: [
      new ImgData("/img/weihnachten_silvester/1.jpg", "Champagner"),
      new ImgData("/img/weihnachten_silvester/2.jpg", "Festtage - Vorspeise"),
      new ImgData("/img/weihnachten_silvester/4.jpg", "Festtage - Hauptgang"),
      new ImgData("/img/weihnachten_silvester/5.jpg", "Festtage - Dessert"),
      new ImgData("/img/weihnachten_silvester/3.jpg", "Sternekoch Alexander Dinter", {bgPos: "top"})
    ]
  })
  */

  /*
  "fischkulinarik": new Schmecken({
    name: "Fischkulinarik",
    paragraphs: [
      "Ein Tag am Meer<br>Für alle Fischverliebten gibt's dienstags frische Meeresspezialitäten im Restaurant und der Bar.",
      "Ab Juli werden wir am Dienstagabend im Gourmet Restaurant und in der Bar wieder kulinarisch ans Meer reisen.<br>Bis dahin verwöhnen wir Sie Dienstags mit unserer Lunchkarte auch am Abend.",
      "Herzlichst ihr Team 5"
    ],
    imgArr: [
      new ImgData("/img/fisch2.jpg", "Fisch und Meeresfrüchte"),
      new ImgData("/img/fisch3.jpg", "Fisch auf grauem Teller"),
      new ImgData("/img/fisch1.jpg", "Fischgericht auf weissem Teller"),
      new ImgData("/img/fisch5.jpg", "Fisch auf goldenem Teller"),
      new ImgData("/img/fisch6.jpg", "Fisch auf weissem Teller")
    ]
  })
  */

  /*
  "immer-dienstags": new Schmecken({
    name: "Immer Dienstags",
    paragraphs: [
      "EINE KULINARISCHE WELTREISE",
      "Unser Gourmet Restaurant (1.OG) und damit unsere Sterneküche hat Dienstags geschlossen.",
      "Gerne können Sie aber einen Platz in unserer Bar/Lounge (EG) oder auf unserer Terrasse für eine kulinarische Weltreise buchen.",
      "Ein 3 Gang Menu sowie Gerichte aus allen 5 Erdteilen stehen Ihnen zur Auswahl."
    ],
    imgArr: [
      new ImgData("/img/dienstags_1.jpg", "Steak"),
      new ImgData("/img/fisch1.jpg", "Fischgericht auf weissem Teller"),
      new ImgData("/img/fisch5.jpg", "Fisch auf goldenem Teller"),
      new ImgData("/img/fisch6.jpg", "Fisch auf weissem Teller"),
      new ImgData("/img/dienstags_2.jpg", "Gemüse mit Reis")
    ]
  })
  */
}
