import {sortBy} from "lodash-es"

import {FoodDish} from "@/models/FoodDish"

export class FoodCategory {
  /**
   * Sanitizes data, sorts foodDishes.
   *
   * @param {Object} data - see graphql/fragments/foodCategory
   * @param {String} data.id
   * @param {Number|null} data.sort
   * @param {String} data.name
   * @param {String|null} data.description
   * @param {Object[]} data.food_dishes
   */
  constructor(data) {
    this.id = data.id
    this.sort = data.sort || Number.MAX_SAFE_INTEGER // sort null last
    this.name = data.name
    this.description = data.description
    this.foodDishes = sortBy(
      data.food_dishes ? data.food_dishes.map(x => new FoodDish(x)) : [],
      d => d.sort
    )
  }
}
