<template>
  <section class="schmecken">
    <h1 class="schmecken__h1">
      {{ content.name }}
    </h1>

    <ParagraphsPrePic
      v-if="content.paragraphs.length"
      class="schmecken__paragraphs-pre-pic"
      :paragraphs="content.paragraphs"
    />

    <GalleryResponsive
      v-if="content.imgArr.length"
      class="schmecken__gallery"
      :img-arr="content.imgArr"
    />

    <SectionPostPic
      v-if="content.sectionPostGallery"
      :content="content.sectionPostGallery"
    />

    <div
      v-if="content.includeTogoTerms"
      class="schmecken__txtblock"
    >
      <TogoTerms />
    </div>

    <UnAvailable
      v-if="content.isUnavailable"
      class="schmecken__un-available"
    />

    <FoodCategoryList
      :location="content.foodCategoryLocation"
      class="schmecken__food-category-list"
    />

    <div
      v-if="content.includeRsvBtn"
      class="schmecken__rsvbtn-wrap"
    >
      <BtnRsv>
        Zur Reservierung
      </BtnRsv>
    </div>

    <div class="schmecken__extlinkwrap">
      <BtnLinkExt
        v-if="content.linkPDFMenu"
        :link="content.linkPDFMenu"
      />
    </div>

    <SectionPostPic
      v-if="content.sectionBottom"
      :content="content.sectionBottom"
    />
  </section>
</template>

<script>
import BtnRsv from "@/components/BtnRsv.vue"
import {content} from "@/models/Schmecken.js"
import {getMeta} from "@/util/route.js"
import FoodCategoryList from "@/components/FoodCategoryList.vue"
import ParagraphsPrePic from "@/components/ParagraphsPrePic.vue"
import SectionPostPic from "@/components/SectionPostPic.vue"

export default {
  name: "Schmecken",

  components: {
    BtnLinkExt: () => import("@/components/BtnLinkExt.vue"),
    BtnRsv,
    FoodCategoryList,
    GalleryResponsive: () => import("@/components/GalleryResponsive.vue"),
    ParagraphsPrePic,
    SectionPostPic,
    TogoTerms: () => import("@/components/TogoTerms.vue"),
    UnAvailable: () => import("@/components/UnAvailable.vue")
  },

  computed: {
    schmeckenKey() {
      return getMeta(this.$route.matched, "schmeckenKey")
    },

    content() {
      return content[this.schmeckenKey]
    }
  }
}
</script>

<style lang="scss">
@use "app";
@use "img";
@use "svg";
@use "txt";
@use "vars";

.schmecken {
  margin-top: app.$margin-to-nav;
  margin-bottom: txt.$blockmargin;
}

.schmecken__h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.schmecken__paragraphs-pre-pic {
  margin-top: 1.5em;
}

.schmecken__gallery {
  margin: 3em 0;
}

.schmecken__un-available {
  margin-top: 6em;
}

.schmecken__food-category-list {
  max-width: vars.$menu-max-w;
  margin: 6em auto 0 auto;
  padding: 0 vars.$body-padding-h;
}

.schmecken__rsvbtn-wrap {
  max-width: vars.$menu-max-w;
  margin: 4em auto 0 auto;
  padding: 0 vars.$body-padding-h;
  text-align: center;
}

.schmecken__extlinkwrap {
  margin-top: 4em;
  text-align: center;
}
</style>
