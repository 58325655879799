<template>
  <div class="food-dish">
    <div class="title">
      {{ foodDish.title }}
    </div>

    <div
      v-if="hasDetails"
      class="details"
    >
      <p
        v-for="p in foodDish.details"
        :key="p"
      >
        {{ p }}
      </p>
    </div>

    <div
      v-if="foodDish.price"
      class="price"
    >
      {{ foodDish.price }}
    </div>
  </div>
</template>

<script>
import {FoodDish} from "@/models/FoodDish"

export default {
  props: {
    foodDish: {
      type: FoodDish,
      required: true
    }
  },

  computed: {
    hasDetails() {
      return this.foodDish.details && this.foodDish.details.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
@use "vars";

.title {
  font-size: 1.1em;
  font-family: vars.$font-fam-deco;
}

.details {
  p {
    margin-top: 0.25em;
  }
}

.price {
  margin-top: 0.25em;
}
</style>
