<template>
  <ApolloQuery
    :query="require('@/graphql/queries/FoodCategoryList.graphql')"
    :variables="{ location, limit }"
    :notify-on-network-status-change="true"
    @result="onQueryResult"
  >
    <template v-slot="{ result: { loading, error, data }}">
      <div
        v-if="loading"
        class="loading"
      >
        Speisekarte wird geladen...
      </div>

      <div v-else>
        <div
          v-if="error"
          class="error"
        >
          Leider kann die Speisekarte nicht geladen werden.
        </div>

        <div v-else-if="data && data.food_categories && data.food_categories.length">
          <FoodCategoryComponent
            v-for="c in foodCategories"
            :key="c.id"
            :food-category="c"
            class="food-category"
          />
        </div>

        <div
          v-else
          class="empty"
        >
          Keine Speisen vorhanden.
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import {sortBy} from "lodash-es"

import {FoodCategory} from "@/models/FoodCategory"
import FoodCategoryComponent from "@/components/FoodCategory.vue"

export default {
  components: {
    FoodCategoryComponent
  },

  props: {
    limit: {
      type: Number,
      default: 25
    },
    /** Load categories from this location. */
    location: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      foodCategories: []
    }
  },

  methods: {
    onQueryResult(result) {
      if (result.loading || result.error || !result.data.food_categories) {
        this.foodCategories = []
        return
      }
      this.foodCategories = sortBy(
        result.data.food_categories.map(x => new FoodCategory(x)),
        c => c.sort
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@use "vars";

.loading {
  text-align: center;
}

.error {
  text-align: center;
}

.empty {
  text-align: center;
}

.food-category {
  margin-top: 6em;

  @media (max-width: vars.$break-mobile) {
    margin-top: 4em;
  }
}
</style>
