import {splitAtNewlines} from "@/util/string"

export class FoodDish {
  /**
   * Sanitizes data.
   *
   * @param {Object} data - see graphql/fragments/foodDish
   * @param {String} data.id
   * @param {Number|null} data.sort
   * @param {String} data.title
   * @param {String|null} data.details
   * @param {String|null} data.price
   */
  constructor(data) {
    this.id = data.id
    this.sort = data.sort || Number.MAX_SAFE_INTEGER // sort null last
    this.title = data.title
    this.details = data.details ? splitAtNewlines(data.details) : []
    this.price = data.price
  }
}
